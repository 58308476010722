{
  ".data..percpu": [126656, 4852],
  ".data.once": [131632, 473],
  ".data..percpu..shared_aligned": [156480, 3216],
  ".init.setup": [589736, 900],
  "__param": [618156, 940],
  ".initcallrootfs.init": [619144, 4],
  ".initcall6.init": [619148, 56],
  ".initcallearly.init": [619204, 28],
  ".initcall5.init": [619232, 24],
  ".initcall4.init": [619256, 36],
  ".initcall1.init": [621936, 20],
  ".initcall7.init": [621956, 16],
  ".initcall2.init": [622168, 20],
  ".initcall3s.init": [627340, 4],
  ".initcall7s.init": [627344, 4]
}
